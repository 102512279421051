.referral-whole {
  padding-top: 48px;
  padding-left: 28px;
  height: 100%;
  overflow: hidden !important;
}
.referral-main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 35px;
}
.referral-heading {
  color: #1d1f2c;

  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
  letter-spacing: -1px;
}

.inputid-label {
  color: #1d1f2c;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135.5%; /* 21.68px */
}
.r-input-btn {
  display: flex;
  align-items: flex-end;
  gap: 35px;
}
.referral-share-btn {
  border-radius: 8px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  color: var(--Neutral-Colors-100, #fff) !important;
  text-align: center !important;

  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  letter-spacing: -0.48px !important;
  text-transform: capitalize !important;

  display: flex;
  height: 54px !important;
  padding: 18px 60px !important;
  justify-content: center;
  align-items: center;
}
.stack-contain {
  align-items: end;
}
/* .Mui-selected {
  border-radius: 6px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  width: 32px !important;
  height: 32px !important;
  padding: 6px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Black-0---Primary, #fff) !important;
  text-align: center !important;

  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 130% !important;
  letter-spacing: 0.07px !important;
  border: none !important;
} */
.column-head-name{
  text-align: start !important;
}

@media (max-width:500px) {
  
  .r-input-btn{
    flex-direction: column;
  }
  .r-input-btn{
    width:100%;
    align-items: center;
  }
  .referral-whole{
    padding-left: 0px;
  }
}
