p {
  padding: 0;
  margin: 0;
}

.profile-head {
  color: #1d1f2c;

  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
  letter-spacing: -1px;
}

.profile-whole {
  padding-top: 24px;
  padding-left: 46px;
  height: 100vh;
}

.profile-div {
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}
.profile-txt {
  color: #1d1f2c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.referral-contain {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;
}
.input-label {
  color: #1d1f2c;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135.5%; /* 21.68px */
  margin-bottom: 7px;
}

.referral-input {
  border: 1px solid rgba(157, 170, 199, 0.25) !important;
  border-radius: 11px !important;
  background: #f4f8ff !important;
  padding-top: 23px !important;
  padding-bottom: 23px !important;
  padding-left: 23px !important;
  font-family: "Montserrat", sans-serif !important;
  width: 360px !important;
  height: 56px;
}
.referral-input::placeholder {
  color: rgba(102, 112, 133, 0.7) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 135% !important; /* 18.9px */
}

.div2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 35px;
}
.div2-r2 {
  display: flex;
  /* align-items: flex-start; */
  gap: 60px;
}
.div2-r3 {
  display: flex;
  gap: 60px;
}
.div3-btn{
    padding-top: 45px;
    padding-bottom: 45px;
}
.profile-connect-btn {
  border-radius: 8px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  height: 57px !important;
  padding: 26px 38px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Neutral-Colors-100, #fff) !important;
  text-align: center !important;

  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 111.111% */
  letter-spacing: -0.54px !important;
  text-transform: capitalize !important;
}

.profile-img{
    height: 112px;
    width: 112px;
    border-radius: 60px;
}
@media (max-width:768px) {

  .div2-r2{
    flex-direction: column;
    gap: 30px;


  }
  .div2-r3{
    flex-direction: column;
    gap: 30px;

  }
  .referral-input{
    width: 100% !important;
  }
  .profile-whole{
    padding-left: 0px;
  }
}