.ClaimProfits__main {
    margin-left: 20px;
    margin-top: 20px;
    min-height: 100vh;

}

.ClaimProfits__main-heading {
    margin-bottom: 35px;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -1px;
    text-align: left;

}


.ClaimProfits__main-content {
    display: flex;
    flex-wrap: wrap;
    /* width: 295px; */
    /* height: 267px; */
    border-radius: 15px;
    border: 1px;
    /* padding: 20px; */
    gap: 25px;
    
}

.ClaimProfits__main-cards img {
    width: 255px;
    height: 160px;
    margin: 20px;
}

.ClaimProfits__main-cards {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    width: 295px;
    height: 267px;
    border: 1px solid #E2E5EF;
    border-radius: 15px;
    cursor: pointer;
}

.ClaimProfits__main-cards button {
    color: #0F68FF;
    border: 1px solid #0F68FF;
    border-radius: 80px;
    width: 138px;
    height: 42px;
    padding: 10px, 24px, 10px, 24px;
    margin-top: 5px;
    margin-left: 20px;
    background-color: transparent;


    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.005em;
}


.ClaimProfits__main-cards:hover{
    scale: 1.02;
}

.ClaimProfits__main-cards img:hover{
    scale: 1;
}

@media (max-width:768px) {
    /* .ClaimProfits__main-cards{
        width: 100%;

    }
    .ClaimProfits__main-cards img{
        width: 100%;


    } */
    
}