/* .css-1ju65hq-MuiDrawer-docked{
  z-index: 5 !important;
} */

.sidebar__image{
  cursor: pointer;
}

/* .navbar__notification {
  display: flex;
  align-items: center;
  margin-top: 7px;
}
.navbar__notification__dropdown {
  background: rgba(248, 248, 248, 0.6) !important;
  color: #1d1f2c !important;

  border: none !important;
  padding: 0 !important;
}
.navbar__notification .dropdown-toggle::after{
  content: none !important;
}
.navbar__notification .dropdown-menu{
  overflow-y: scroll;
  overflow-x: hidden;
  width: 269px;
  height: 283px;
}
.navbar__notification .dropdown-menu.show {
  border: 1px solid #6670851a;
  background-color: #F9F9FC;
border-radius: 10px;
  color: #1d1f2c !important;
  padding-top: 15px;
}
.navbar__notification .dropdown-item {
  color: #1d1f2c !important;
  padding-left: 0px;
  padding-right: 0px;
 
}

.navbar__notification__dropdown__title {
  font-family: "Montserrat";
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  color: #1d1f2c;
}
.blue-dot {
  height: 5px;
  width: 5px;
  background: #0f68ff;
  border-radius: 50px;
  margin-top: 5px;
}
.dot-heading {
  display: flex;
  align-items: start;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 0.5px solid #6670851A;
  
 
}
.notify-heading {
  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  color: #1d1f2c;
}
.notify-subtxt {
  
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: #c9c9c9;
}
.notify-txt-btm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
}
.hour-txt {
  
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #667085;
}

.read-txt{
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: right;
  color: #0f68ff;
} */
.navbar__notification {
  display: flex;
  align-items: center;
}
.navbar__notification__dropdown {
  background: rgba(248, 248, 248, 0.6) !important;
  color: #1d1f2c !important;

  border: none !important;
  padding: 0 !important;
}
.navbar__notification .dropdown-toggle::after{
  content: none !important;
}
.navbar__notification .dropdown-menu{
  overflow-y: scroll;
  overflow-x: hidden;
  width: 350px;
  height: 368px;
}
.navbar__notification .dropdown-menu.show {
  border: 1px solid #6670851a;
  /* background:  rgba(248, 248, 248, 0.6); */
  background-color: #F9F9FC;
border-radius: 10px;
  color: #1d1f2c !important;
  padding-top: 19.52px;
  padding-bottom: 19.52px;
}
.navbar__notification .dropdown-item {
  color: #1d1f2c !important;
  padding-left: 0px;
  /* padding-right: 0px; */
 
}

.navbar__notification__dropdown__title {
  /* font-family: Montserrat; */
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 15.61px;
  font-weight: 600;
  line-height: 20.3px;
  color: #1d1f2c;
  border-bottom: 0.5px solid #6670851a;
}
.blue-dot {
  height: 5px;
  width: 5px;
  background: #0f68ff;
  border-radius: 50px;
  margin-top: 5px;
}
.dot-heading {
  display: flex;
  align-items: start;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 0.5px solid #6670851A;
}
.notify-heading {
  font-size: 13.01px;
  font-weight: 500;
  line-height: 15.86px;
  color: #1d1f2c;
}
.notify-subtxt {
  /* font-family: Poppins; */
  font-size: 13.01px;
  font-weight: 400;
  line-height: 19.52px;
  color: #c9c9c9;
  /* padding-right: 15px; */
}
.notify-txt-btm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
}
.hour-txt {
  /* font-family: Poppins; */
  font-size: 10.41px;
  font-weight: 400;
  line-height: 15.61px;
  text-align: left;
  color: #667085;
}

.read-txt{
  font-size: 10.41px;
  font-weight: 400;
  line-height: 15.61px;
  text-align: right;
  color: #0f68ff;
}



/* After build Styling*/

.Mui-selected {
  color: #0f68ff !important;
  background:none !important;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
  border-radius: 8px !important;
  transition: border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s !important;


}
.menu-items {
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
}

.Avatar-div {
  /* margin-top: 30%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Avatar-div-mobile {
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*dropdown avatar-top */

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  gap: 10px;
}
.css-9mgopn-MuiDivider-root {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  border: none !important;
}
/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  top: 53px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px !important;
} */

.List-div {
  /* margin-top: 20% !important; */

  padding-left: 12px !important;
  padding-right: 8px !important;
  /* color: var(--Grey-500---Primary, #667085) !important; */

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
}
/* icons */
.css-cveggr-MuiListItemIcon-root {
  min-width: auto !important;
}
.css-h4y409-MuiList-root {
  padding-top: 32px !important;
}
/* icon-label */
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  /* padding-top: 12px !important;
  padding-bottom: 12px !important; */
  gap: 12px !important;
}

.list-icons {
  min-width: 24px !important;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  color: var(--Grey-500---Primary, #667085) !important;
}
.Logout-div {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  padding-left: 27px;
  cursor: pointer;
}
.Logout-txt {
  color: var(--Secondary-Red-500, #f04a4a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
}
.items-main-heading {
  padding-left: 16px;
  color: var(--Grey-500---Primary, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 19.2px */
  padding-bottom: 8px;
  text-transform: uppercase;
}
/* .muiltr-1jkhpm7.active {
  color: rgb(255, 255, 255);
  pointer-events: none;
  transition: border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background-color: rgba(255, 255, 255, 0.1) !important;
} */


.list-item {
  color: var(--Grey-500---Primary, #667085) !important;

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
  display: flex;
  align-items: center;
  gap: 12px;
 
}
.list-item-active {
  color: #0f68ff !important;
  background:none !important;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
  border-radius: 8px;
  /* padding-top: 12px !important;
  padding-bottom: 12px !important; */
  /* background-color: var(--Blue-Text, #0F68FF) !important; */
  transition: border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s !important;
}
.list-item:hover {
  color: #0f68ff !important;
  border-radius: 8px !important;
  /* background-color: var(--Blue-Text, #0f68ff) !important; */
}

.css-18sg6k4-MuiPaper-root-MuiDrawer-paper {
  /* background-color: #111827 !important; */
  background-color: #fff !important;
}

/* .css-ciy9n4-MuiPaper-root-MuiAppBar-root{
  z-index: -1 ;
} */

.css-mrxozk-MuiDrawer-docked .MuiDrawer-paper {
  width: 280px;
  box-sizing: border-box;
  z-index: 1 !important;
}
/* List -txt / sidebar*/

.css-10hburv-MuiTypography-root {
  margin: 0;

  display: block;
  font-size: 14px !important;

  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
}

.css-ivnu1y-MuiPaper-root-MuiAppBar-root {
  z-index: -1 !important;
  background-color: #fff !important;
}
/* Menu Icon */
.css-134qg7o-MuiButtonBase-root-MuiIconButton-root {
  color: black !important;
}
.css-hyum1k-MuiToolbar-root {
  background: #fff !important;
  border-bottom: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  box-shadow: none !important;
}
.css-i1kkii-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
  z-index: 2 !important;
}

/* toolbar*/
.css-55u6o0-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;

  color: #fff;
  -webkit-transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: calc(100% - 280px);
  margin-left: 280px;
}
/* left side toolbar*/

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  /* background-color: #111827 !important; */
  background-color: #fff !important;
  color: #fff !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  outline: 0;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
/* Toolbar css */
.css-15075hz-MuiPaper-root-MuiAppBar-root {
  z-index: 2 !important;
}
.css-ciy9n4-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;

  color: #fff;
  -webkit-transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.css-jzk4qw-MuiPaper-root-MuiAppBar-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  /* background-color: #111827 !important; */
  background-color: #fff !important;
  color: #fff;
  -webkit-transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: calc(100% - 240px);
  margin-left: 240px;
}

/* menu bar and profile icon */
.css-hyum1k-MuiToolbar-root {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  justify-content: space-between;
}

.btn-wallet {
  border-radius: 8px;
  background: var(--Blue-Text, #0f68ff);
  display: flex;
  height: 34px;
  padding: 14px 12px;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--Black-0---Primary, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  width: 140px;
  margin-top: 7px;
}

.vertical-line {
  border-right: 1px solid #f0f1f3;
  height: 20px;
}

.setting-notify {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  /* width: 100%; */
}
.btn-search {
  border: none;
  border-radius: 8px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Grey-500---Primary, #667085);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.07px;
}

/* search -bar */

.css-1mgg4b8-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
}

.Avatar-top-div {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  margin-right: 16px;
  margin-left: 16px;
  cursor: pointer;
  /* width: 100%; */
}
.notify-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  gap: 20px;
  /* gap: 751px; */
}

@media (max-width: 992px) {
  .Avatar-top-div {
    justify-content: flex-end;
    /* margin-left: 34px; */
  }
}
@media (max-width: 768px) {
  .Avatar-top-div {
    justify-content: flex-end;
    margin-left: 34px;
  }
}
