.user-card-main {
  border: 1px solid #f0f1f3;
  background: #ffffff;
  /* width: 262px; */
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
}

.card-h {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  color: #667085;
}

.card-value {
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  color: #1d1f2c;
  padding-top: 4px;
}


@media(max-width:768px){
    .user-card-main{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .card-h-p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}