h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px !important;
}
.admindashboard__main {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  margin-top: 40px;
}

.admindashboard__main-left {
  width: 359px;
  height: 100%;
  margin-right: 42px;
}

.admindashboard__main-heading h6 {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  letter-spacing: -1px;
  text-align: left;
  color: #1d1f2c;
}

.admindashboard__main-heading p {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: #667085;
  padding-top: 4px;
}

.admindashboard__main-heading-txt h6 {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 21.68px;
  text-align: left;
  color: #1d1f2c;
  padding-top: 40px;
  padding-bottom: 15px;
}

#dropdown-basic1 {
  width: 359px;
  height: 56.56px;
}
.dropdown__menu {
  border-radius: 8px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  display: flex !important;
  padding: 14px 20px !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 8px !important;
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  margin-bottom: 15px;
}
.btm-input {
  margin-bottom: 0px !important;
}
.show1 {
  width: 359px;
  text-align: center !important;
  align-items: center;
  border: none !important;
}

.dropdown__text {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.dropdown__text:hover {
  background-color: #fff !important;
}

.dropdown__text p {
  border: 0.5px solid #e2e2e2;
  border-radius: 10px;
  width: 100% !important;
  height: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.dropdown__text p:hover {
  background-color: #0f68ff !important;
  color: #fff !important;
}

.admindashboard__main-input {
  width: 359px;
  height: 56.56px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: #1d1f2c;
  border: 1px solid #f0f1f3;
  border-radius: 8px;
  margin-bottom: 15px;
  padding-left: 19px;
}
.admindashboard__main-btn {
  padding-top: 40px;
}

.arrow-img {
  padding-right: 5px;
}
.admindashboard__main-btn button {
  background-color: #0f68ff;
  color: #fff;
  border: transparent;
  border-radius: 8px;
  width: 179px;
  height: 54px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #0f68ff;
}

.table-main-head1 {
  font-family: "Montserrat";
  font-size: 25px;
  font-weight: 600;
  line-height: 32.5px;
  text-align: left;
  color: #1d1f2c;
}

.table-main-head2 {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #1d1f2c;
}

.table-top-lhs2 p {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: right;
  color: #0f68ff;
}

.admin__column-head {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #667085;
}

.admindashboard__main-right {
  width: 100%;
  min-height: 100vh;
  border-radius: 20px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  padding: 28px 61.5px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.admindashboard__main-text-p1 {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  letter-spacing: -1px;
  text-align: left;
  padding-bottom: 25px;
  padding-top: 50px;
}
.admindashboard__main-text-btn {
  width: 218px;
  height: 54px;
  border-radius: 8px;
  background: #12d878;
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: center;
  border: none;
  cursor: pointer;
}
.admindashboard__main-text-p2 {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: left;
  padding-top: 30px;
  color: #1d1f2c;
}
.admindashboard__main-text-p3 {
  font-family: "Montserrat";
  font-size: 11px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
  padding-top: 5px;
  color: #6f6f6f;
  text-wrap: wrap;
  width: 100%;
}

@media (max-width: 900px) {
  .admindashboard__main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .admindashboard__main-left {
    width: 100%;
    margin-right: 0;
  }
  #dropdown-basic1 {
    width: 100%;
  }
  .admindashboard__main-heading-txt .dropdown {
    width: 100%;
  }
  .admindashboard__main-input {
    width: 100%;
  }
  .admindashboard__main-btn button {
    width: 100%;
  }
}
