.maindashboardinfo-maincard {
  display: flex;
  gap: 13px;
  padding-top: 21px;
}
.css-9eg9l8-MuiTypography-root {
  margin-bottom: 0px !important;
}
.maindashboardinfo__main-heading h6 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0px !important;
  margin-top: 40px;
}

/* styles/SearchBar.css */

.search-input-icon {
  position: relative;
}
.search-input-icon input::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.005em;
  color: #667085;
}
.search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  /* padding: 11px 11px 15px 16px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.005em;
  border: 1px solid #f0f1f3;
  border-radius: 8px;
  width: 271px;
  height: 44px;
  padding-left: 40px;
}
.table-above-row-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
}
.table-main-head-info {
  display: flex;
  align-items: center;
  gap: 82px;
}
.user-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 0px !important;
}

.search-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  top: 50% !important;
}

.search-icon:hover {
  stroke: #007bff; /* Change the color on hover */
}

@media (max-width: 768px) {
  .maindashboardinfo-maincard {
    flex-direction: column;
  }
  .table-above-row-info {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .table-main-head-info {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  .search-input {
    width: 100%;
    padding-left: 49px;
  }
  .search-bar,.search-input-icon, .table-top-lhs, .filter-btn ,.drop-main, .dropdown-menu.show,.dropdown-toggle{
    width: 100%;
  }
}


/* for empty screen */
.maindashboard__empty-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 100vh;
}

.maindashboard__empty-text h6{
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #1D1F2C;
} 
.maindashboard__empty-text p{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  color: #667085;
} 
