.inner-column
{
  color: #0F68FF !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22.4px !important;
}
.columns-user {
  border-bottom: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: #FCFCFC;
}
.no-referral-contain {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 198px;
  padding-bottom: 197px;
}

.no-referral-txt {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #1d1f2c;
  padding-top: 15px;
}

.no-referral-des{
font-size: 12px;
font-weight: 400;
line-height: 15.6px;
color: #667085;
padding-top: 10px;

}