.main-section-wallet {
    /* color: #000 !important; */
  
    border-radius: 0px 0px 15px 15px;
    /* background: rgba(255, 255, 255, 0.9); */
    backdrop-filter: blur(11.050000190734863px);
  }
  
  /* .modal-whole{
    z-index: 0;
  } */
  
  .modal-content {
    border-radius: 140px !important;
  
  }
  
  .modal-header {
    border-radius: 15px 15px 0 0 !important;
    border-bottom: none !important;
    justify-content: space-around;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  
  .connect-p {
    padding-bottom: 21px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .subText{
    font-size: 15px;
    line-height: 25px;
    text-align: center;
  }
  
  .checkbox-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
  }
  
  .checkbox-design {
    width: 14.114px;
    height: 14.114px;
    margin-right: 5.6px;
  }
  
  .choose-txt {
    padding-top: 36px;
    padding-bottom: 11px;
    text-align: center;
  }
  
  .meta-common {
    margin-left: 7.56px;
    margin-top: 8.29px;
  }
  
  .meta-diff {
    margin-top: 8.29px;
  }
  
  .meta-cards {
    padding-bottom: 35.29px;
  }
  
  .close-btn-img {
    position: absolute;
    right: 30px;
    top: 22px;
    cursor: pointer;
  }
  
  .close-btn-img:hover {
    transform: scale(1.24);
    cursor: pointer;
    backdrop-filter: blur(11.050000190734863px);
  }
  
  .meta-common:hover {
    transform: scale(1.02);
    cursor: pointer;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
  }
  
  .meta-diff:hover {
    transform: scale(1.02);
    cursor: pointer;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
  }

  @media (max-width:500px) {
    .wallet-main-img{
      width: 100%;

    }
    
  }