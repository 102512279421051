p {
  padding: 0;
  margin: 0;
}

.dashboard__main-heading {
  margin-top: 41px;
  margin-bottom: 32px;
}

.dashboard__main-heading h2 {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  letter-spacing: -1px;
  text-align: left;
  color: #1d1f2c;
}

.dashboard__main-heading p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: #667085;
}

/* .container-whole {
  background: var(--grey-0-primary-screen-color, red) !important;
} */
.dash-row-1 {
  padding-top: 32px;
  padding-bottom: 24px;
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
  gap: 24px;
  /* width: 100%; */
}

.total-balance-card {
  border-radius: 20px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 40%;
}

.dash-heading {
  color: var(--Black-500---Primary, #1d1f2c);

  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 36.4px */
  letter-spacing: -1px;
}

.dash-subhead {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
  padding-top: 4px;
}

.total-balance-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.dollar-percent-contain {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-bottom: 16px;
  padding-top: 4px;
}

.dollar-value {
  color: var(--Black-500---Primary, #1d1f2c);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  /* 41.6px */
  letter-spacing: -1px;
}

.percent-value {
  color: rgba(0, 194, 78, 0.84);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 22.4px */
  letter-spacing: -1px;
}

.updated-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.btns-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.Claim-btn {
  border-radius: 8px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  width: 148px !important;
  height: 48px !important;
  padding: 14px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Black-0---Primary, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important;
  /* 18.2px */
  letter-spacing: 0.07px !important;
  text-transform: capitalize !important;
}

.buy-nft-btn {
  border-radius: 8px !important;
  border: 1px solid var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  width: 148px;
  height: 48px !important;
  padding: 14px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Blue-Text, #0f68ff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important;
  /* 18.2px */
  letter-spacing: 0.07px !important;
  text-transform: capitalize !important;
}

.info-div {
  border-radius: 8px;
  background: rgba(243, 174, 69, 0.12);
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
}

.info-txt {
  color: var(--Secondary-Yellow-500, #f3ae45);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 22.4px */
  letter-spacing: 0.07px;
}

.web3-nfts-card {
  border-radius: 20px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  /* width: 736px; */
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 60%;
}

.myweb-btn-contain {
  display: flex;

  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nfts-txt {
  color: var(--Black-500---Primary, #1d1f2c);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 26px */
}

.select-nft-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
  padding-top: 4px;
}

.buy-web3nft-btn {
  border-radius: 8px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  width: 189px;
  height: 34px !important;
  padding: 14px 12px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Black-0---Primary, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important;
  /* 18.2px */
  text-transform: capitalize !important;
}

.bar-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.barchart-btm {
  padding-top: 32px;
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
  width: 100%;
  /* gap: 56px; */
}

.invested-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.invested-txt {
  color: var(--Grey-500---Primary, #667085);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 0% !important;
  /* 22.4px */
}

.invested-value {
  color: var(--Black-500---Primary, #1d1f2c);
  padding-top: 2px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 26px */
  letter-spacing: -1px;
}

/* .cards-imgs {
  display: flex;
  align-items: center;
  gap: 18px;
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 1050px;
} */
::-webkit-scrollbar {
  display: none;
}

.bar-contain {
  border-radius: 20px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 28px 24px 35px 24px;
  flex-direction: column;
  width: 50%;
}

.linebar-contain {
  border-radius: 20px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;

  padding: 28px 24px 33px 24px;
  flex-direction: column;
  width: 50%;
}

.dropdown-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-yearly {
  border-radius: 8px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
}

.dropdown-toggle::after {
  background-repeat: no-repeat !important;
  border: none !important;
  content: url("/public/Images/Dashboard/arrow-down.svg") !important;
  vertical-align: middle !important;
}

.nft-growth-txt {
  color: var(--Black-500---Primary, #1d1f2c);

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 26px */
}

.view-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.row-2 {
  display: flex;

  gap: 24px;

  /* padding: 28px 24px; */
}

.row-3 {
  border-radius: 20px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  padding: 28px 24px;
  margin-top: 24px;
  
  margin-bottom: 24px;
}

.columns-name {
  border-bottom: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--grey-0-primary-screen-color, #f9f9fc);
}

.column-head-dash {
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: 160%;
  text-align: center !important;
  /* 22.4px */
}

.row-id {
  color: var(--Black-500---Primary, #1d1f2c) !important;

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
}

.row-same {
  color: var(--Black-500---Primary, #1d1f2c) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 160% !important;
  /* 22.4px */
}

.table-main-head {
  color: var(--Black-500---Primary, #1d1f2c);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 26px */
}

.table__row {
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
}

.table__row p {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #667085;
  padding-left: 16px;
}

.filter-btn {
  border: none;
  border-radius: 8px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Grey-500---Primary, #667085);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* 22.4px */
}

.dropdown-week {
  border-radius: 8px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  display: flex !important;
  padding: 14px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important;
  /* 22.4px */
}

.table-view-img {
  display: flex;
  padding: 8px;
  /* gap:6px; */
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
}

.flex-img {
  padding: 6px;
}

.table-top-lhs {
  display: flex;
  align-items: center;
  gap: 16px;
}

.table-above-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
}

.hr-above {
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  opacity: 1;
  margin: 0px;
}

/* cells padding */
.css-1ex1afd-MuiTableCell-root {
  padding: 16px 24px !important;
}

.status-txt {
  border-radius: 8px;
  background: rgba(79, 190, 136, 0.12);
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  width: max-content;
}

@media (max-width: 1200px) {
  .dash-row-1 {
    flex-wrap: wrap;
  }

  .row-2 {
    flex-wrap: wrap;
  }

  .bar-contain,
  .linebar-contain {
    width: 100%;
  }

  .total-balance-card {
    width: 100%;
  }

  .web3-nfts-card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .myweb-btn-contain {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  
}

@media (max-width: 500px) {
  .barchart-btm {
    flex-direction: column;
  }

  .dropdown-text-container {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .table-above-row {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .btns-container {
    flex-wrap: wrap;
  }

  .btns-container button {
    width: 100% !important;
  }
}
