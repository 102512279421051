@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body,button,input,input::placeholder {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-10hburv-MuiTypography-root{
  font-family: "Montserrat", sans-serif !important;

}
.css-1ygcj2i-MuiTableCell-root,.css-1ex1afd-MuiTableCell-root  {
  font-family: "Montserrat", sans-serif !important;

}

